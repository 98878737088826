import { IAppComponentProps } from "../resources/component-interface";
import React from "react";
import styles from "./Button.module.scss";

export type ButtonRole = "primary" | "secondary";

interface IComponentProps extends IAppComponentProps {
    onClick?: () => void;
    disabled?: boolean;
    children: React.ReactNode;
    role?: ButtonRole;
}

const Button: React.FC<IComponentProps> = ({ disabled, onClick, children, className, role = 'primary' }) => {
    const classes = styles.btn + ' '+ styles[(role as string)] + ' ' + className;
    return (
        <button className={classes} disabled={disabled} onClick={onClick}>
            {children}
        </button>
    );
};

export default Button;
