import * as React from "react";
import { useCallback, useState } from "react";

export enum ELevel {
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
}

export interface IMessage {
    level: ELevel;
    type: string;
    message: string;
}

interface IContextProps {
    messages: IMessage[];
    addMessage: (message: IMessage) => void;
    setMessages: (messages: IMessage[]) => void;
    dismissMessages: () => void;
}

export const MessageContext = React.createContext<IContextProps>({} as IContextProps);

interface IContextProviderProps {
    children: React.ReactNode;
}

const MessageProvider = (props: IContextProviderProps) => {
    const [messages, setMessages] = useState<IMessage[]>([]);

    const dismissMessages = useCallback(() => {
        setMessages([]);
    }, [setMessages]);

    const addMessage = useCallback(
        (message: IMessage) => {
            messages.push(message);
            setMessages(messages);
        },
        [messages, setMessages],
    );

    return (
        <MessageContext.Provider
            value={{
                messages,
                setMessages,
                addMessage,
                dismissMessages,
            }}
        >
            {props.children}
        </MessageContext.Provider>
    );
};

export default MessageProvider;
