import axios from "axios";
import config from "./config";

/**
 *
 * @param baseUrl
 */
const getApiEndpoint = (baseUrl: string): string => {
    return baseUrl + config.apiFeauturedBlock;
};

/**
 *
 * @param baseUrl
 */
const getCmsBlock = async (baseUrl: string): Promise<string> => {
    const res = await axios.get(getApiEndpoint(baseUrl));
    return res.data;
};

export default getCmsBlock;
