import React from "react";
import AppConfigProvider, { ECartMode, IAppConfig } from "./context/AppConfigProvider";
import Cart from "./components/Cart";
import LocaleProvider from "./context/LocaleProvider";
import CartProvider from "./context/CartProvider";
import MessageProvider from "./context/MessageProvider";
import Overlay from "./components/Overlay";

interface IAppProps {
    config: IAppConfig;
}

const App: React.FC<IAppProps> = ({ config }) => {
    return (
        <AppConfigProvider config={config}>
            <LocaleProvider>
                <MessageProvider>
                    <CartProvider>
                        <Cart />
                        {config.mode === ECartMode.MINI_CART && <Overlay />}
                    </CartProvider>
                </MessageProvider>
            </LocaleProvider>
        </AppConfigProvider>
    );
};

export default App;
