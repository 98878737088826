import { REMOVE_CART_ITEM_MUTATION } from "../../resources/gql/cart";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { RemoveCartItem } from "../../resources/types/RemoveCartItem";
import {ICartResult} from "./cart-result-interface";
import injectCartResult from "./inject-cart-result";

const removeCartItem = async (
    client: ApolloClient<NormalizedCacheObject>,
    cartId: string,
    cartItemId: number,
): Promise<ICartResult> => {
    const result = await client.query<RemoveCartItem>({
        query: REMOVE_CART_ITEM_MUTATION,
        variables: { cartId, cartItemId },
    });

    return injectCartResult(client, cartId, result.errors || []);
};

export default removeCartItem;
