import React from "react";
import { IAppComponentProps } from "../../resources/component-interface";
import styles from "./ProductCarousel.module.scss";
import ProductCard from "./ProductCard";
import Arrow, { EDirection } from "./Arrow";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
interface IComponentProps extends IAppComponentProps {
    products: any[];
}

const ProductCarousel: React.FC<IComponentProps> = ({ className = "", style = {}, products }) => {
    const navigationPrevRef: React.Ref<any> = React.useRef(null)
    const navigationNextRef: React.Ref<any> = React.useRef(null)

    return (
        <div className={styles.wrapper + " " + className} style={style}>
            <Swiper
                className={styles.swiper}
                modules={[Navigation, Pagination]}
                slidesPerView={1}
                spaceBetween={15}
                loop={false}
                onInit={(swiper) => {
                    // @ts-ignore
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    // @ts-ignore
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                    swiper.navigation.init();
                    swiper.navigation.update();
                }}
                pagination={{ clickable: true }}
            >
                { products.map(product => {
                    return <SwiperSlide className={styles.slide} key={product.sku}>
                        <ProductCard product={product} />
                    </SwiperSlide>;
                }) }
                <div className={styles.prev} ref={navigationPrevRef}>
                    <Arrow direction={EDirection.prev} />
                </div>
                <div className={styles.next} ref={navigationNextRef}>
                    <Arrow direction={EDirection.next} />
                </div>
            </Swiper>
        </div>
    );
};

export default ProductCarousel;
