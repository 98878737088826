import React, { useCallback, useContext } from "react";
import { IAppComponentProps } from "../resources/component-interface";
import styles from "./Header.module.scss";
import { CartContext } from "../context/CartProvider";
import { LocaleContext } from "../context/LocaleProvider";
import ClipLoader from "react-spinners/ClipLoader";
import Messages from "./Messages";
import { ReactComponent as BauLogo } from '../assets/svg/logo.svg';
import { ReactComponent as CloseX } from '../assets/svg/x.svg';
import { Mobile } from "../service/media";

interface IComponentProps extends IAppComponentProps {
    itemsCount: number;
}

const Header: React.FC<IComponentProps> = ({ className = "", style = {}, itemsCount }) => {
    const cart = useContext(CartContext);
    const { t } = useContext(LocaleContext);

    const handleClose = useCallback(() => {
        cart.setOpen(false);
    }, [cart]);
    return (
        <div className={className + " " + styles.wrapper} style={style}>
            <div className={styles.innerWrapper}>
                <Mobile>
                    <BauLogo className={styles.logo} />
                </Mobile>
                {cart.loading && (
                    <div className={styles.loading}>
                        <div className={styles.spinner}>
                            <ClipLoader size={10} />
                        </div>
                        <div className={styles.text}>{t("Updating...")}</div>
                    </div>
                )}
                <div onClick={handleClose} className={styles.closeButton}>
                    <CloseX />
                </div>
            </div>
            <Messages />
        </div>
    );
};

export default Header;
