import React, { useCallback, useContext } from "react";
import { IAppComponentProps } from "../resources/component-interface";
import styles from "./Messages.module.scss";
import { MessageContext } from "../context/MessageProvider";
import { LocaleContext } from '../context/LocaleProvider';
import { CartContext } from '../context/CartProvider';

interface IComponentProps extends IAppComponentProps {}

const Messages: React.FC<IComponentProps> = ({ className = "", style = {} }) => {
    const message = useContext(MessageContext);
    const cart = useContext(CartContext);
    const { t } = useContext(LocaleContext);

    // useEffect(() => {
    //     let timeoutId: any;
    //
    //     if (message.messages.length > 0) {
    //         timeoutId = setTimeout(() => message.dismissMessages(), 10000);
    //     }
    //
    //     return () => {
    //         timeoutId && clearTimeout(timeoutId);
    //     };
    // }, [message]);

    const handleOpenLogin = useCallback(() => {
        cart.setLoginOpen(true);
        message.setMessages([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart.setLoginOpen, message.setMessages]);

    if (!message.messages.length) {
        return <></>;
    }

    return (
        <div className={styles.wrapper + " " + className} style={style}>
            {message.messages.map((message, idx) => {
                if (message.type === "graphql-coupon-requires-login") {
                    return (
                        <div key={idx} className={styles.message + " " + styles[message.level]}>
                            {t("You need to be logged-in in order to use this coupon.")}
                            {!cart.loginOpen && (<>&nbsp; <a onClick={handleOpenLogin} href={"#"}>{t("Log-in now")}</a></>)}
                        </div>
                    );
                } else {
                    return <div key={idx} className={styles.message + " " + styles[message.level]}>{message.message}</div>;
                }
            })}
        </div>
    );
};

export default Messages;
