import * as React from "react";

export enum ECartMode {
    MINI_CART = "mini-cart",
    CART_SUMMARY = "cart-summary",
    CHECKOUT_SUMMARY = "checkout-summary"
}

export interface IAppConfig {
    baseUrl: string;
    formKey: string;
    storeCode: string;
    currency: string;
    locale: string;
    mode: ECartMode;
    socialLogin?: any
    cmsBlockBg?: string;
    cmsBlockColor?: string;
}

interface IContextProps extends IAppConfig {}

export const AppConfigContext = React.createContext<IContextProps>({} as IContextProps);

interface IContextProviderProps {
    config: IAppConfig;
    children: React.ReactNode;
}

const AppConfigProvider = (props: IContextProviderProps) => {
    return (
        <AppConfigContext.Provider
            value={{
                ...props.config,
            }}
        >
            {props.children}
        </AppConfigContext.Provider>
    );
};

export default AppConfigProvider;
