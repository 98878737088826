import React, { useContext } from "react";
import { IAppComponentProps } from "../../resources/component-interface";
import styles from "./GrandTotal.module.scss";
import { GetCart_cart_prices } from "../../resources/types/GetCart";
import TotalRow, {RowType} from "./TotalRow";
import { LocaleContext } from "../../context/LocaleProvider";

interface IComponentProps extends IAppComponentProps {
    totals: GetCart_cart_prices;
    loading?: boolean;
}

const GrandTotal: React.FC<IComponentProps> = ({ className = "", style = {}, totals, loading }) => {
    const { t } = useContext(LocaleContext);

    const grandTotal = totals.grand_total;

    return (
        <div className={className + " " + styles.wrapper} style={style}>
            <TotalRow
                type={RowType.grandTotal}
                className={styles.total}
                label={t("Grand Total")}
                amount={Number(grandTotal?.value)}
                loading={loading}
            />
        </div>
    );
};

export default GrandTotal;
