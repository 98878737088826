import { UPDATE_CART_ITEM_MUTATION } from "../../resources/gql/cart";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { UpdateCartItem } from "../../resources/types/UpdateCartItem";
import {ICartResult} from "./cart-result-interface";
import injectCartResult from "./inject-cart-result";

const updateCartItem = async (
    client: ApolloClient<NormalizedCacheObject>,
    cartId: string,
    cartItemId: number,
    qty: number,
): Promise<ICartResult> => {
    const result = await client.query<UpdateCartItem>({
        query: UPDATE_CART_ITEM_MUTATION,
        variables: { cartId, cartItemId, qty },
    });

    return injectCartResult(client, cartId, result.errors || []);
};

export default updateCartItem;
