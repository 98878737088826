import axios from 'axios';

/**
 * Login via API
 * @param baseUrl
 * @param formKey
 * @param username
 * @param password
 */
const login = async (baseUrl: string, formKey: string, username: string, password: string): Promise<string> => {
    try {
        const res = await axios.post(baseUrl + "customer/ajax/login?form_key=" + formKey, {
            username,
            password
        }, {
            headers: {'X-Requested-With': 'XMLHttpRequest'}
        });

        if (res.data.errors) {
            console.error(res.data.message);
            return res.data.message;
        }

        return "";
    } catch (e) {
        console.error((e as any).message);
        return (e as any).message;
    }
};

export default login;
