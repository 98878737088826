import React from "react";
import { useContext } from "react";
import { LocaleContext } from "../../context/LocaleProvider";
import styles from "./ProductName.module.scss";

type IComponentProps = {
    name: string | null;
    qtyIncrement: number | null;
}

const ProductName: React.FC<IComponentProps> = ({ name, qtyIncrement }) => {
    const { t } = useContext(LocaleContext);

    const completeName = (qtyIncrement ?? 1) > 1 ? `${name} - ${t('pack of %1', [qtyIncrement])}` : name;

    return (
        <div className={styles.productName}>{completeName}</div>
    );
};

export default ProductName;
