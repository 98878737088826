import React from "react";
import { IAppComponentProps } from "../../resources/component-interface";
import { ProductInformation } from "../../resources/types/ProductInformation";
import styles from "./ProductRating.module.scss";
import ReactStars from "react-rating-stars-component";

interface IComponentProps extends IAppComponentProps {
  product: ProductInformation;
}

const calculateRating = (product: ProductInformation) => {
  if (product.review_count === 0) {
    return 0;
  }

  const totalRating = (product: ProductInformation): number => {
    return product.reviews.items.reduce((acc, review) => {
      if (review) return acc + review.average_rating;
      else return acc;
    }, 0);
  };

  const rating = totalRating(product) / product.review_count;

  return 5 * (rating / 100);
};

const ProductRating: React.FC<IComponentProps> = ({ className = "", style = {}, product }) => {
  return (
    <div className={styles.wrapper}>
      <ReactStars
        count={5}
        color="#C2D1D8"
        activeColor="#2e2e2e"
        size={16}
        value={calculateRating(product)}
        edit={false}
      />
      { product.review_count > 0 && <span className={styles.count}>{product.review_count}</span>}
    </div>
  );
};

export default ProductRating;
