import React, { useCallback, useContext } from "react";
import { IAppComponentProps } from "../resources/component-interface";
import styles from "./Overlay.module.scss";
import { CartContext } from "../context/CartProvider";

interface IComponentProps extends IAppComponentProps {}

const Overlay: React.FC<IComponentProps> = ({ className = "", style = {} }) => {
    const cart = useContext(CartContext);

    const wrapperClassName = className + " " + styles.wrapper + " " + (cart.open ? styles.open : styles.closed);

    const handleClose = useCallback(() => {
        cart.setOpen(false);
    }, [cart]);

    return <div onClick={handleClose} className={wrapperClassName} style={style} />;
};

export default Overlay;
