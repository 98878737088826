import React, {useContext} from "react";
import { IAppComponentProps } from "../resources/component-interface";
import {CartInformation} from "../resources/types/CartInformation";
import Skeleton from "react-loading-skeleton";
import {LocaleContext} from "../context/LocaleProvider";
import { ReactComponent as FreeShippingIcon } from '../assets/svg/gift.svg';
import styles from './FreeShippingInformation.module.scss';

interface IComponentProps extends IAppComponentProps {
    cart: CartInformation;
    isHeavy: boolean,
    loading?: boolean
}

const FreeShippingInformation: React.FC<IComponentProps> = ({ cart, isHeavy, loading, className = "", style = {} }) => {
    const { formatPrice, t } = useContext(LocaleContext);

    const grandTotal = cart.prices?.grand_total;
    const freeShippingThreshold = cart.free_shipping_threshold;

    if (null === freeShippingThreshold || loading) {
        return <Skeleton />
    }

    const hasFreeShipping = Number(grandTotal?.value) >= Number(freeShippingThreshold);

    const remaining = formatPrice(freeShippingThreshold - Number(grandTotal?.value));
    const percent = (Number(grandTotal?.value) / Number(freeShippingThreshold)) * 100;

    const wrapperClass = className + " " + styles.wrapper;

    if (isHeavy) {
        return (
            <div className={ wrapperClass + ' ' + styles.heavyShipping }>
                <div className={styles.bold}>{t('Spedizione per ordini superiori a 40kg!')}</div>
            </div>
        )
    }

    if (hasFreeShipping) {
        return(
            <div className={ wrapperClass }>
                <div className={styles.bold + ' ' + styles.greenText}>{t('Hai raggiunto la spedizione gratuita!')}</div>
                <div className={styles.active}>
                    <div className={styles.barContainer}>
                        <div className={styles.bgBar}>
                            <div style={{width: '100%'}} className={styles.activeBar}>
                                <FreeShippingIcon className={ styles.icon }/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={ wrapperClass }>
            <div>{t('Free shipping starting from')}&nbsp;<span className={styles.bold}>{ formatPrice(Number(freeShippingThreshold)) }</span></div>
            <div className={styles.remaining}>
                {t('Only left')}&nbsp;<span className={styles.bold}>{ remaining }</span>
                <div className={styles.barContainer}>
                    <div className={styles.bgBar}>
                        <div style={{width: percent + '%'}} className={styles.activeBar}>
                            <FreeShippingIcon className={ styles.icon }/>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};

export default FreeShippingInformation;
