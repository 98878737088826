import {CartInformation} from "../../resources/types/CartInformation";

const filterInvalidCartItems = (cart: CartInformation | null): CartInformation  | null => {
    if (!cart) {
        return null;
    }

    return {
        ...cart,
        items: cart?.items?.filter(a => a) || null
    }
}

export default filterInvalidCartItems;
