import React, { useContext, useCallback } from "react";
import { GetCart_cart } from "../resources/types/GetCart";
import { LocaleContext } from "../context/LocaleProvider";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styles from "./RewardPoints.module.scss";
import { ReactComponent as Icon } from "../assets/svg/x.svg";

interface IComponentProps {
  cart: GetCart_cart;
  loading?: boolean;
  removeRewardPoints?: () => void;
}

const RewardPoints: React.FC<IComponentProps> = ({ cart, loading, removeRewardPoints }) => {
  const { t, formatPrice } = useContext(LocaleContext);

  const applied_reward_points = cart.applied_reward_points;

  const handleRemoveRewardsPoint = useCallback(() => {
    removeRewardPoints && removeRewardPoints();
  }, [removeRewardPoints]);

  if (applied_reward_points === null) {
      return <></>;
  }

  if (applied_reward_points && applied_reward_points.points === 0) {
      return <></>;
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        {t("Applied")} {loading ? <Skeleton inline={true} width={50} /> : applied_reward_points?.points}{" "}
        {t("Rewards Points")}
        <br />
        <div onClick={handleRemoveRewardsPoint} className={styles.remove}>
          <div className={styles["remove-label"]}>{t("Remove")}</div>
          <Icon className={styles["remove-icon"]} />
        </div>
      </div>
      <div className={styles.amount}>
        {loading ? <Skeleton /> : formatPrice(-(applied_reward_points?.money.value ?? 0))}
      </div>
    </div>
  );
};

export default RewardPoints;
