import React, { useContext } from "react";
import { IAppComponentProps } from "../../resources/component-interface";
import { ProductInformation } from "../../resources/types/ProductInformation";
import styles from "./ProductCard.module.scss";
import { LocaleContext } from "../../context/LocaleProvider";
import ProductName from "../CartItem/ProductName";
import { AppConfigContext } from "../../context/AppConfigProvider";
import ProductRating from "./ProductRating";

interface IComponentProps extends IAppComponentProps {
    product: ProductInformation;
}

const getProductUrl = (product: ProductInformation, baseUrl: string) => {
    return `${baseUrl}${product.canonical_url}`;
}

const ProductCard: React.FC<IComponentProps> = ({ className = "", style = {}, product }) => {
    const { t, formatPrice } = useContext(LocaleContext);
    const { baseUrl } = useContext(AppConfigContext);

    return (
        <a href={getProductUrl(product, baseUrl)} className={styles.wrapper + " " + className} style={style} >
            <div className={styles.picture}>
                {product.thumbnail?.url ? <img alt={product.name || ""} src={product.thumbnail?.url} /> : ""}
            </div>
            <div className={styles.details}>
                <div className={styles.brand}>{product.brand}</div>
                <ProductName name={product.name} qtyIncrement={product.qty_increment} />
                { product.__typename === 'ConfigurableProduct' ? t('As low as:') : '' }
                <div className={styles.price}>
                    {formatPrice((product.price_range.minimum_price.final_price.value || 0) * (product.qty_increment ?? 1))}
                </div>
                <ProductRating product={product} />
            </div>
        </a>
    );
};

export default ProductCard;
