const esES = {
    "Your cart": "Tu carrito",
    "%1 products": "%1 productos",
    Subtotal: "Subtotal",
    "Grand Total": "Total general",
    "Apply a discount coupon code": "Aplicar cupón",
    "Product added to cart": "Producto añadido a la cesta",
    "Proceed to checkout": "Tramitar pedido",
    "Coupon code %1 has been applied successfully": "El código de cupón %1 se aplicó correctamente",
    "Coupon code has been removed": "Su cupón fue removido con éxito.",
    Remove: "Eliminar",
    Quantity: "Cantidad",
    "Apply Coupon": "Aplicar cupón",
    "Please wait...": "Espere por favor...",
    "Updating...": "actualizando ...",
    "Add to cart": "Añadir al carrito",
    "Suggested products": "Productos sugeridos",
    "Special price": "Special price",
    "Secure shopping": "Compra segura",
    "You need to be logged-in in order to use this coupon.": "Debe iniciar sesión para poder utilizar este cupón.",
    "Log-in now": "Inicia sesión ahora",
    "Log-in": "Acceso",
    "Log-in the website": "Accedi al sito",
    "Forgot your password?": "¿Olvidaste tu contraseña?",
    "Your online payments a re protected and we also accept cash on delivery":
    "Sus pagos en línea están protegidos",
    "You are now logged in": "Ahora está conectado"
};

export default esES;
