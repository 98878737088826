import React, { useCallback, useContext, useState } from "react";
import { IAppComponentProps } from '../resources/component-interface';
import styles from './Login.module.scss';
import { LocaleContext } from '../context/LocaleProvider';
import Button from './Button';
import login from '../service/login';
import { AppConfigContext, ECartMode } from '../context/AppConfigProvider';
import { ELevel, MessageContext } from '../context/MessageProvider';
import { CartContext } from '../context/CartProvider';

interface IComponentProps extends IAppComponentProps {

}

const Login: React.FC<IComponentProps> = ({ className = "", style = {} }) => {
    const { t } = useContext(LocaleContext);
    const { setMessages } = useContext(MessageContext);
    const { baseUrl, formKey, mode } = useContext(AppConfigContext);
    const { loading, setLoading, setLoginOpen } = useContext(CartContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleEmailChange = useCallback((e) => {
        setEmail(e.target.value);
    }, [setEmail]);

    const handlePasswordChange = useCallback((e) => {
        setPassword(e.target.value);
    }, [setPassword]);

    const handleSubmit = useCallback(() => {
        (async () => {
            setLoading(true);
            const errorMessage = await login(baseUrl, formKey, email, password);
            if (!errorMessage) {
                if (mode === ECartMode.MINI_CART) {
                    const event = new Event("onMspEnhancedMiniCartLogin");
                    (window as any).dispatchEvent(event);
                    setLoginOpen(false);
                    setMessages([{
                        type: "login-success",
                        message: t('You are now logged in'),
                        level: ELevel.SUCCESS
                    }]);
                } else {
                    window.location.reload();
                }
            } else {
                setMessages([{
                    type: "login-error",
                    message: errorMessage,
                    level: ELevel.ERROR
                }]);
            }
            setLoading(false);
        })();
    }, [email, password, setMessages, baseUrl, setLoading, formKey, setLoginOpen, mode, t]);

    return (
        <div className={className + " " + styles.wrapper}>
            <div className={styles.header}>{t("Log-in")}</div>
            <div className={styles.field}>
                <input disabled={loading} value={email} onChange={handleEmailChange} type={"text"} placeholder={t("Email address")} />
            </div>
            <div className={styles.field}>
                <input disabled={loading} value={password} onChange={handlePasswordChange} type={"password"} placeholder={t("Password")} />
            </div>
            <div className={styles.field}>
                <Button disabled={loading} onClick={handleSubmit} className={styles.login}>{t("Log-in the website")}</Button>
            </div>
            <a href={baseUrl + 'customer/account/forgotPassword'}>{t('Forgot your password?')}</a>
        </div>
    );
};

export default Login;
