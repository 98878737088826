import React, { useContext } from "react";
import { IAppComponentProps } from "../../resources/component-interface";
import styles from "./ReadOnlyQuantity.module.scss";
import {LocaleContext} from "../../context/LocaleProvider";

interface IComponentProps extends IAppComponentProps {
  qty: number;
}

const ReadOnlyQuantity: React.FC<IComponentProps> = ({
  className = "",
  qty,
}) => {
  const { t } = useContext(LocaleContext)

  return (
    <div className={styles.wrapper + " " + className}>
      <div className={styles.qty}>
          <span className={styles.label}>{ t('Qty') }: </span>
          <span className={styles.value}>{ qty }</span>
      </div>
    </div>
  );
};

export default ReadOnlyQuantity;
