import gql from "graphql-tag";

export const PRODUCT_INFORMATION_FRAGMENT = gql`
    fragment ProductInformation on ProductInterface {
        sku
        name
        special_price
        qty_increment
        brand
        canonical_url
        price_range {
            minimum_price {
                regular_price {
                    currency
                    value
                }
                final_price {
                    currency
                    value
                }
            }
        }
        thumbnail {
            url
        }
        review_count
        reviews {
            items {
                average_rating
            }
        }
    }
`;

export const CART_INFORMATION_FRAGMENT = gql`
    fragment CartInformation on Cart {
        shipping_total {
            amount
            label
            code
        }
        minicart_footer
        is_heavy
        has_carnet
        has_free_shipping_rule
        free_shipping_threshold
        crosssell_products {
            ...ProductInformation
        }
        applied_reward_points {
            money {
                currency
                value
            }
            points
        }
        items {
            id
            quantity
            qty_increment
            ... on ConfigurableCartItem {
                configurable_options {
                    id
                    option_label
                    value_label
                }
            }
            product {
                sku
                name
                special_price
                stock_status
                brand
                price_range {
                    minimum_price {
                        regular_price {
                            currency
                            value
                        }
                    }
                }
                thumbnail {
                    label
                    url
                }
            }
            prices {
                price {
                    currency
                    value
                }
                row_total_incl_tax {
                    currency
                    value
                }
                row_total {
                    currency
                    value
                }
                total_item_discount {
                    currency
                    value
                }
            }
        }
        prices {
            grand_total {
                currency
                value
            }
            discounts {
                amount {
                    currency
                    value
                }
                label
            }
            subtotal_including_tax {
                currency
                value
            }
            applied_taxes {
                amount {
                    currency
                    value
                }
                label
            }
        }
        applied_coupons {
            code
        }
    }

    ${PRODUCT_INFORMATION_FRAGMENT}
`;

export const GET_CART_QUERY = gql`
    query GetCart($cartId: String!) {
        cart(cart_id: $cartId) {
            ...CartInformation
        }
    }

    ${CART_INFORMATION_FRAGMENT}
`;

export const REMOVE_CART_ITEM_MUTATION = gql`
    mutation RemoveCartItem($cartId: String!, $cartItemId: Int!) {
        removeItemFromCart(input: { cart_id: $cartId, cart_item_id: $cartItemId }) {
            cart {
                ...CartInformation
            }
        }
    }

    ${CART_INFORMATION_FRAGMENT}
`;

export const UPDATE_CART_ITEM_MUTATION = gql`
    mutation UpdateCartItem($cartId: String!, $cartItemId: Int!, $qty: Float!) {
        updateCartItems(input: { cart_id: $cartId, cart_items: [{ cart_item_id: $cartItemId, quantity: $qty }] }) {
            cart {
                ...CartInformation
            }
        }
    }

    ${CART_INFORMATION_FRAGMENT}
`;

export const APPLY_COUPON_MUTATION = gql`
    mutation ApplyCoupon($cartId: String!, $couponCode: String!) {
        applyCouponToCart(input: { cart_id: $cartId, coupon_code: $couponCode }) {
            cart {
                ...CartInformation
            }
        }
    }

    ${CART_INFORMATION_FRAGMENT}
`;

export const REMOVE_COUPON_MUTATION = gql`
    mutation RemoveCoupon($cartId: String!) {
        removeCouponFromCart(input: { cart_id: $cartId }) {
            cart {
                ...CartInformation
            }
        }
    }

    ${CART_INFORMATION_FRAGMENT}
`;

export const ADD_TO_CART_MUTATION = gql`
    mutation AddProduct($cartId: String!, $sku: String!) {
        addSimpleProductsToCart(input: { cart_id: $cartId, cart_items: [{ data: { quantity: 1, sku: $sku } }] }) {
            cart {
                ...CartInformation
            }
        }
    }

    ${CART_INFORMATION_FRAGMENT}
`;

export const REMOVE_REWARD_POINTS_MUTATION = gql`
    mutation RemoveRewardPoints($cartId: ID!) {
        removeRewardPointsFromCart(cartId: $cartId ) {
            cart {
                ...CartInformation
            }
        }
    }

    ${CART_INFORMATION_FRAGMENT}
`;
