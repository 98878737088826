const frFR = {
    "Your cart": "Votre panier",
    "%1 products": "%1 produit",
    Subtotal: "Sous-total",
    "Grand Total": "Total",
    "Apply a discount coupon code": "Entrer un code promo",
    "Product added to cart": "Produit ajouté au panier",
    "Proceed to checkout": "Procéder à l’achat",
    "Coupon code %1 has been applied successfully": "Le code promo %1 a été appliqué avec succès",
    "Coupon code has been removed": "Le code promo a été supprimé",
    Remove: "Supprimer",
    Quantity: "Quantité",
    "Apply Coupon": "Appliquer",
    "Please wait...": "Veuillez patienter...",
    "Updating...": "Mise a jour...",
    "Add to cart": "Ajoutez au panier",
    "Suggested products": "Productos sugeridos",
    "Special price": "En promotion",
    "Secure shopping": "Compra segura",
    "You need to be logged-in in order to use this coupon.": "Vous devez être connecté pour utiliser ce code promo.",
    "Log-in now": "Connectez-vous maintenant ",
    "Log-in": "Connectez-vous",
    "Log-in the website": "Connectez-vous",
    "Forgot your password?": "Mot de passe oublié ?",
    "Your online payments a re protected and we also accept cash on delivery":
        "Sus pagos en línea están protegidos",
    "You are now logged in": "Vous êtes maintenant connecté"
};

export default frFR;
