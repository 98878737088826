import { useContext, useEffect, useState } from "react";
import {AppConfigContext} from "../context/AppConfigProvider";
import getCmsBlock from "../service/get-cms-block";

/**
 * Asynchronously get featured block
 */
const useFeaturedBlock = () => {
    const [featuredBlock, setFeaturedBlock] = useState<string>('');
    const { baseUrl } = useContext(AppConfigContext);

    useEffect(() => {
        (async () => {
            const res = await getCmsBlock(baseUrl);

            const decoded = JSON.parse(res);

            if (decoded.hasOwnProperty('content') && decoded.content) {
                setFeaturedBlock(decoded.content);
            } else {
                setFeaturedBlock('');
            }
        })();
    }, [baseUrl]);

    return featuredBlock;
};

export default useFeaturedBlock;
