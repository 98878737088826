import * as React from "react";
import t from "../service/translate";
import { useCallback, useContext } from "react";
import { AppConfigContext } from "./AppConfigProvider";

interface IContextProps {
    t: (s: string, args?: any) => string;
    formatPrice: (n: number) => string;
    locale: string;
}

export const LocaleContext = React.createContext<IContextProps>({} as IContextProps);

interface IContextProviderProps {
    children: React.ReactNode;
}

const LocaleProvider = (props: IContextProviderProps) => {
    const config = useContext(AppConfigContext);
    const { locale, currency } = config;

    /**
     * Localized version of "t"
     * @param s
     * @param args
     */
    const tl = useCallback(
        (s: string, args?: any) => {
            return t(locale, s, args);
        },
        [locale],
    );

    /**
     * Format price
     * @param n
     */
    const formatPrice = (n: number): string => {
        if (!locale) {
            return String(n);
        }

        const dashLocale = locale.replace("_", "-");
        return new Intl.NumberFormat(dashLocale, { style: "currency", currency }).format(n);
    };

    return (
        <LocaleContext.Provider
            value={{
                t: tl,
                formatPrice,
                locale,
            }}
        >
            {props.children}
        </LocaleContext.Provider>
    );
};

export default LocaleProvider;
