import React, { ImgHTMLAttributes } from "react";
import styles from "./Arrow.module.scss";
import { ReactComponent as Back} from '../../assets/svg/back.svg';

export enum EDirection {
    prev = "prev",
    next = "next",
}

interface IComponentProps extends ImgHTMLAttributes<HTMLImageElement> {
    direction: EDirection;
}

const Arrow: React.FC<IComponentProps> = props => {
    const { className } = props;
    const classNameArrow = props.direction === EDirection.next ? 'arrow-next' : 'arrow-prev'
    return (
        <Back
            className={styles.wrapper + " " + className + " " + classNameArrow}
            style={props.direction === EDirection.next ? { transform: "rotate(180deg)"} : {}}
        />
    );
};

export default Arrow;
