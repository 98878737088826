import React, { useCallback } from "react";
import { IAppComponentProps } from "../../resources/component-interface";
import styles from "./Quantity.module.scss";

interface IComponentProps extends IAppComponentProps {
  qty: number;
  qtyIncrement: number | null;
  onQtyUpdate?: (qty: number) => void;
  disabled?: boolean;
}

const Quantity: React.FC<IComponentProps> = ({
  className = "",
  qty,
  qtyIncrement,
  onQtyUpdate,
  disabled,
}) => {
  const updateQty = useCallback(
    (q) => {
      onQtyUpdate && onQtyUpdate(q);
    },
    [onQtyUpdate],
  );

  const increase = () => {
    updateQty(qty + (qtyIncrement ?? 1));
  };

  const decrease = () => {
    if (qty > (qtyIncrement ?? 1)) {
      updateQty(qty - (qtyIncrement ?? 1));
    }
  };

  return (
    <div className={styles.wrapper + " " + className}>
      <div className={styles.qty}>
        <button onClick={decrease} disabled={(qty / (qtyIncrement ?? 1)) === 1 }>-</button>
        <input readOnly type="number" value={qty / (qtyIncrement ?? 1)} disabled={disabled} />
        <button onClick={increase}>+</button>
      </div>
    </div>
  );
};

export default Quantity;
