import React, {useCallback, useContext, useMemo} from "react";
import {IAppComponentProps} from "../resources/component-interface";
import {
    GetCart_cart_items,
    GetCart_cart_items_ConfigurableCartItem,
    GetCart_cart_items_ConfigurableCartItem_configurable_options
} from "../resources/types/GetCart";
import styles from "./CartItem.module.scss";
import {LocaleContext} from "../context/LocaleProvider";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Quantity from "./CartItem/Quantity";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {ProductStockStatus} from "../resources/types/globalTypes";
import ProductName from "./CartItem/ProductName";
import {ReactComponent as TrashIcon} from '../assets/svg/trash.svg';
import {AppConfigContext, ECartMode} from "../context/AppConfigProvider";
import ReadOnlyQuantity from "./CartItem/ReadOnlyQuantity";
import {CartContext} from "../context/CartProvider";

interface IComponentProps extends IAppComponentProps {
    item: GetCart_cart_items;
    onRemoveItem?: () => void;
    onQtyUpdate?: (qty: number) => void;
    loading?: boolean;
}

const CartItem: React.FC<IComponentProps> = ({
    className = "",
    style = {},
    item,
    onRemoveItem,
    onQtyUpdate,
    loading
}) => {
    const { t, formatPrice } = useContext(LocaleContext);
    const { mode } = useContext(AppConfigContext);
    const cart = useContext(CartContext);

    const handleRemoveItem = useCallback(() => {
        !loading && onRemoveItem && onRemoveItem();
    }, [loading, onRemoveItem]);

    const canRemoveProducts = (mode !== ECartMode.CHECKOUT_SUMMARY) || (mode === ECartMode.CHECKOUT_SUMMARY && !cart.cart?.has_carnet);

    const hasOptions =
        (item as GetCart_cart_items_ConfigurableCartItem).configurable_options?.length > 0;
    const isOutOfStock = item.product.stock_status === ProductStockStatus.OUT_OF_STOCK;

    return (
        <div className={className + " " + styles.wrapper} style={style}>
            <div className={styles.picture}>
                {item.product.thumbnail?.url ? (
                    <img alt={item.product.name || ""} src={item.product.thumbnail?.url} />
                ) : (
                    ""
                )}
            </div>
            <div className={styles.details}>
                {isOutOfStock && <div className={styles.outOfStock}><FontAwesomeIcon icon={faExclamationTriangle} style={{color: 'red'}} />&nbsp;{t('This product is out of stock')}</div> }
                <ProductName name={item.product.name} qtyIncrement={item.qty_increment} />
                <div className={styles.rowTotal}>
                    {loading ? <Skeleton /> : formatPrice(item.prices?.row_total_incl_tax.value || 0)}
                </div>
                {hasOptions && (item as GetCart_cart_items_ConfigurableCartItem).configurable_options.map(
                    (op :GetCart_cart_items_ConfigurableCartItem_configurable_options | null, index) =>
                        <div className={styles.option} key={index}>
                            <span className={styles.option__label}>{op?.option_label}</span>:&nbsp;
                            <span className={styles.option__value}>{op?.value_label}</span>
                        </div>
                    )}
                { mode !== ECartMode.CHECKOUT_SUMMARY && <Quantity disabled={loading}
                          qty={item.quantity}
                          qtyIncrement={item.qty_increment}
                          onQtyUpdate={onQtyUpdate} /> }
                { mode === ECartMode.CHECKOUT_SUMMARY && <ReadOnlyQuantity qty={item.quantity} /> }
                { canRemoveProducts && <div className={styles.remove + " " + (loading ? styles.disabled : "")}>
                    <TrashIcon onClick={handleRemoveItem} />
                </div>}
            </div>
        </div>
    );
};

export default CartItem;
