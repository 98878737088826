import React, {useContext} from "react";
import { IAppComponentProps } from "../resources/component-interface";
import styles from "./Scalapay.module.scss";
import {CartInformation} from "../resources/types/CartInformation";
import {ReactComponent as ScalapayLogo} from "../assets/svg/scalapay.svg";
import {LocaleContext} from "../context/LocaleProvider";
import Skeleton from "react-loading-skeleton";

interface IComponentProps extends IAppComponentProps {
    cart: CartInformation,
    loading: boolean
}

const Scalapay: React.FC<IComponentProps> = ({ cart, loading, className = "", style = {} }) => {
    const { formatPrice } = useContext(LocaleContext)
    const wrapperClassName = className + " " + styles.wrapper;
    const scalapayQuota = cart.prices?.grand_total?.value ? formatPrice(cart.prices.grand_total.value / 3) : <Skeleton />;

    if (loading) {
        return <Skeleton />;
    }

    return <div className={wrapperClassName} style={style}>o 3 rate da <span className={styles.price}>{ scalapayQuota }</span> senza interessi. <ScalapayLogo className={styles.logo}/></div>;
};

export default Scalapay;
