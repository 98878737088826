import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { CartInformation } from "../resources/types/CartInformation";
import useApolloClient from "../hooks/use-apollo-client";
import getCart from "../service/cart/get-cart";
import removeCartItem from "../service/cart/remove-cart-item";
import updateCartItem from "../service/cart/update-cart-item";
import applyCoupon from "../service/cart/apply-coupon";
import removeRewardPoints from "../service/cart/remove-reward-points";
import { ELevel, IMessage, MessageContext } from "./MessageProvider";
import { GraphQLError } from "graphql";
import { LocaleContext } from "./LocaleProvider";
import addToCart from "../service/cart/add-to-cart";
import { AppConfigContext, ECartMode } from './AppConfigProvider';
import {ICartResult} from "../service/cart/cart-result-interface";

interface IContextProps {
    removeItem: (itemId: number) => void;
    updateItem: (itemId: number, qty: number) => void;
    applyCoupon: (couponCode: string) => void;
    addProduct: (sku: string) => void;
    removeRewardPoints: () => void;
    loading: boolean;
    setLoading: (status: boolean) => void;
    initialLoading: boolean;
    cart: CartInformation | null;
    couponCode: string;
    open: boolean;
    setOpen: (status: boolean) => void;
    loginOpen: boolean;
    setLoginOpen: (status: boolean) => void;
    canCheckout: boolean;
    errors: readonly GraphQLError[]
}

export const CartContext = React.createContext<IContextProps>({} as IContextProps);

interface IContextProviderProps {
    children: React.ReactNode;
}

const CartProvider = (props: IContextProviderProps) => {
    const apolloClient = useApolloClient();
    const { mode, baseUrl } = useContext(AppConfigContext);
    const message = useContext(MessageContext);
    const { t } = useContext(LocaleContext);
    const [loading, setLoading] = useState(false);
    const [cart, setCart] = useState<CartInformation | null>(null);
    const [cartId, setCartId] = useState("");
    const [initialLoading, setInitialLoading] = useState(true);
    const [open, setOpen] = useState(mode !== ECartMode.MINI_CART);
    const [loginOpen, setLoginOpen] = useState(false);
    const [errors, setErrors] = useState<readonly GraphQLError[]>([])
    const [canCheckout, setCanCheckout] = useState(false);

    // Close login if closing cart
    useEffect(() => {
        if (!open) {
            setLoginOpen(false);
        }
    }, [open, setLoginOpen])

    const showError = useCallback((resultErrors: readonly GraphQLError[]) => {
        const messages: IMessage[] = [];

        resultErrors.forEach((error: GraphQLError) => {
            const errorCategory = error.extensions?.category;
            // if (initialLoading) {
            //     alert(error.message);
            // }

            messages.push({
                level: ELevel.ERROR,
                type: errorCategory,
                message: error.message,
            });
        });

        message.setMessages(messages);
    }, [ message ]);

    const handleCartOperation = useCallback(
        (op: () => Promise<ICartResult>, successMessage: string = "", mutationOp: boolean = false) => {
            setLoading(true);

            const shouldReloadPage = mode === ECartMode.CART_SUMMARY;

            (async () => {
                const [ cartResult, resultErrors ] = await op();
                setErrors(resultErrors);
                if(cartResult) {
                    setCart(cartResult);
                }

                if (resultErrors.length) {
                    showError(resultErrors);
                } else {
                    message.dismissMessages();

                    if (successMessage) {
                        message.setMessages([
                            {
                                level: ELevel.SUCCESS,
                                type: "",
                                message: successMessage,
                            },
                        ]);

                        if (mutationOp && shouldReloadPage) {
                            window.location.reload();
                            return;
                        }
                    }
                }

                setInitialLoading(false);
                setLoading(false);
            })();
        },
        [mode, showError, message],
    );

    useEffect(() => {
        setCanCheckout(!errors.find(err => err.path?.includes('items')));
    }, [errors])

    const loadCart = useCallback((cartIdentifier) => {
        if (cartIdentifier) {
            handleCartOperation(() => getCart(apolloClient, cartIdentifier));
        }
    }, [handleCartOperation, apolloClient]);

    const serializedCart = JSON.stringify(cart);

    useEffect(() => {
        if (cart?.items?.length === 0) {
            setOpen(false);

            if (mode !== ECartMode.MINI_CART) {
                window.location.href = baseUrl + 'checkout/cart';
            }
        }

        const event = new Event("onMspEnhancedMiniCartUpdate");
        (window as any).dispatchEvent(event);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serializedCart, setOpen, baseUrl, mode, cart?.items?.length]);

    useEffect(() => {
        (window as any).enhancedMiniCart = {
            setCartId: (newCartId: string) => {
                setCartId(newCartId);
                if (open && newCartId !== cartId) {
                    loadCart(newCartId);
                }
            },

            refresh: () => {
                loadCart(cartId);
            },

            setOpen: (status: boolean) => {
                if (status) {
                    if (mode === ECartMode.MINI_CART) {
                        const event = new CustomEvent(
                            "onMspEnhancedMiniCartOpen",
                            {detail: {cartId: cartId}},
                        );
                        (window as any).dispatchEvent(event);
                        loadCart(cartId);
                    }
                }
                setOpen(status);
            },

            toggle: () => {
                if (!open) {
                    if (mode === ECartMode.MINI_CART) {
                        loadCart(cartId);
                    }
                }
                setOpen(!open);
            },
        };
    }, [cartId, loadCart, setCartId, setOpen, mode, open]);

    const handleRemoveItem = useCallback(
        (itemId: number) => {
            handleCartOperation(() => removeCartItem(apolloClient, cartId, itemId), "", true);
        },
        [apolloClient, cartId, handleCartOperation],
    );

    const handleUpdateItem = useCallback(
        (itemId: number, qty: number) => {
            handleCartOperation(() => updateCartItem(apolloClient, cartId, itemId, qty), "", true);
        },
        [apolloClient, cartId, handleCartOperation],
    );

    const handleApplyCoupon = useCallback(
        (couponCode: string) => {
            const successMessage = couponCode
                ? t("Coupon code %1 has been applied successfully", [couponCode])
                : t("Coupon code has been removed");

            handleCartOperation(() => applyCoupon(apolloClient, cartId, couponCode), successMessage, true);
        },
        [apolloClient, cartId, handleCartOperation, t],
    );

    const handleAddProduct = useCallback(
        (sku: string) => {
            const successMessage = t("Product added to cart");
            handleCartOperation(() => addToCart(apolloClient, cartId, sku), successMessage, true);
        },
        [apolloClient, cartId, handleCartOperation, t],
    );

    const appliedCoupons = cart?.applied_coupons || [];
    const couponCode = appliedCoupons.length ? String(appliedCoupons[0]?.code) : "";

    const handleRemoveRewardPoints = useCallback(
        () => {
            const successMessage = t("Reward points removed");
            handleCartOperation(() => removeRewardPoints(apolloClient, cartId), successMessage, true);
        },
        [apolloClient, cartId, handleCartOperation, t],
    );
    return (
        <CartContext.Provider
            value={{
                loading,
                setLoading,
                cart,
                open,
                setOpen,
                loginOpen,
                setLoginOpen,
                initialLoading,
                addProduct: handleAddProduct,
                removeItem: handleRemoveItem,
                updateItem: handleUpdateItem,
                applyCoupon: handleApplyCoupon,
                removeRewardPoints: handleRemoveRewardPoints,
                couponCode,
                canCheckout,
                errors
            }}
        >
            {props.children}
        </CartContext.Provider>
    );
};

export default CartProvider;
