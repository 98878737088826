import { APPLY_COUPON_MUTATION, REMOVE_COUPON_MUTATION } from "../../resources/gql/cart";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { CartInformation } from "../../resources/types/CartInformation";
import { ApplyCoupon } from "../../resources/types/ApplyCoupon";
import { RemoveCoupon } from "../../resources/types/RemoveCoupon";
import {ICartResult} from "./cart-result-interface";
import injectCartResult from "./inject-cart-result";

const removeCoupon = async (
    client: ApolloClient<NormalizedCacheObject>,
    cartId: string,
): Promise<ICartResult> => {
    const result = await client.query<RemoveCoupon>({
        query: REMOVE_COUPON_MUTATION,
        variables: { cartId },
    });

    return [
        result.data.removeCouponFromCart?.cart as CartInformation,
        result.errors || []
    ];
};

const applyCoupon = async (
    client: ApolloClient<NormalizedCacheObject>,
    cartId: string,
    couponCode: string,
): Promise<ICartResult> => {
    if (couponCode === "") {
        return removeCoupon(client, cartId);
    }

    const result = await client.query<ApplyCoupon>({
        query: APPLY_COUPON_MUTATION,
        variables: { cartId, couponCode },
    });

    return injectCartResult(client, cartId, result.errors || []);
};

export default applyCoupon;
