import React, { useCallback, useContext } from "react";
import { IAppComponentProps } from "../resources/component-interface";
import styles from "./Coupon.module.scss";
import AddCoupon from "./Coupon/AddCoupon";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocaleContext } from "../context/LocaleProvider";

interface IComponentProps extends IAppComponentProps {
    couponCode: string;
    onUpdateCoupon?: (couponCode: string) => void;
    disabled?: boolean;
}

const Coupon: React.FC<IComponentProps> = ({ className = "", style = {}, couponCode, onUpdateCoupon, disabled }) => {
    const { t } = useContext(LocaleContext);

    const handleRemoveCoupon = useCallback(() => {
        !disabled && onUpdateCoupon && onUpdateCoupon("");
    }, [disabled, onUpdateCoupon]);

    if (!couponCode) {
        return <AddCoupon disabled={disabled} onAddCoupon={onUpdateCoupon} />;
    }

    return (
        <div className={styles.wrapper + " " + className} style={style}>
            <div className={styles.coupon}>{couponCode}</div>
            <div onClick={handleRemoveCoupon} className={styles.remove + " " + (disabled ? styles.disabled : "")}>
                <div className={styles.label}>{t("Remove")}</div>
                <div className={styles.icon}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </div>
        </div>
    );
};

export default Coupon;
