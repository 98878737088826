import { REMOVE_REWARD_POINTS_MUTATION } from "../../resources/gql/cart";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { RemoveRewardPoints } from "../../resources/types/RemoveRewardPoints";
import {ICartResult} from "./cart-result-interface";
import injectCartResult from "./inject-cart-result";

const removeRewardPoints = async (
    client: ApolloClient<NormalizedCacheObject>,
    cartId: string,
): Promise<ICartResult> => {
    const result = await client.query<RemoveRewardPoints>({
        query: REMOVE_REWARD_POINTS_MUTATION,
        variables: { cartId },
    });

    console.log('removeRewardPoint result', result);
    return injectCartResult(client, cartId, result.errors || []);
};

export default removeRewardPoints;
