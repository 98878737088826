import React, { useCallback, useContext, useState } from "react";
import { IAppComponentProps } from "../../resources/component-interface";
import styles from "./AddCoupon.module.scss";
import { LocaleContext } from "../../context/LocaleProvider";
import { ReactComponent as Toggle} from '../../assets/svg/back.svg';


interface IComponentProps extends IAppComponentProps {
    onAddCoupon?: (couponCode: string) => void;
    disabled?: boolean;
}

const AddCoupon: React.FC<IComponentProps> = ({ className = "", style = {}, onAddCoupon, disabled }) => {
    const { t } = useContext(LocaleContext);
    const [couponCode, setCouponCode] = useState("");
    const [open, setOpen ] = useState(false);


    const handleAddCoupon = useCallback(() => {
        onAddCoupon && onAddCoupon(couponCode);
    }, [couponCode, onAddCoupon]);

    const handleCouponCodeChange = useCallback(
        (e: any) => {
            setCouponCode(e.target.value);
        },
        [setCouponCode],
    );

    const handleClick = function () {
        setOpen(!open);
    }

    const toggleClassname = styles.toggle + ' ' + (open ? styles.opened : styles.closed)

    return (
        <div className={className + " " + styles.wrapper} style={style}>
            <div className={styles.header}>
                <div className={styles.title} onClick={ handleClick }>
                    {t("Have you got a promo code?")} <Toggle className={toggleClassname}/>
                </div>
            </div>

            {open && <div className={styles.body}>
                <div className={styles.input}>
                    <input disabled={disabled} type={"text"} value={couponCode} placeholder={t('Promo code')} onChange={handleCouponCodeChange} />
                    <button className={styles.button} disabled={disabled} onClick={handleAddCoupon}>
                        {t("Validate")}
                    </button>
                </div>
            </div>}
        </div>
    );
};

export default AddCoupon;
