import React, {useContext} from "react";
import { IAppComponentProps } from "../../resources/component-interface";
import useCmsBlock from "../../hooks/use-cms-block";
import {AppConfigContext} from "../../context/AppConfigProvider";

interface IComponentProps extends IAppComponentProps {
}

const CmsBlock: React.FC<IComponentProps> = ({ className = "", style = {} }) => {
    const cmsBlock = useCmsBlock();
    const { cmsBlockBg, cmsBlockColor } = useContext(AppConfigContext);

    const cmsBlockStyle = {
        ...style,
        backgroundColor: cmsBlockBg,
        color: cmsBlockColor,
    } as React.CSSProperties;

    return (
        <>
            {cmsBlock && <div className={className} style={cmsBlockStyle}>
                <div dangerouslySetInnerHTML={{__html: cmsBlock}}/>
            </div>}
        </>

    );
};

export default CmsBlock;
